import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { useTranslation } from 'react-i18next'
import Spacer from '../components/uielements/Spacer'
import MainTitle from '../components/uielements/MainTitle'
import FlexWrapper from '../components/uielements/flexWrapper'
import Button from '../components/uielements/Button'
import styled from 'styled-components'
import BlogSlider from '../components/buildingBlocks/BlogSlider'
import Columns from '../components/uielements/Columns'
import Wrapper from '../components/Wrapper'
import { Helmet } from 'react-helmet'
import Seo from '../components/seo'
import Image from '../components/uielements/Image'
import RichContent from '../components/uielements/RichContent/richContent'

const FaqBoxTitle = styled.div`
    padding: 40px;
    text-align: center;
    background: ${({ theme }) => theme.blue};
    border-bottom: 3px solid ${({ theme }) => theme.purple};
    color: #fff;
    box-shadow: 0 0 10px #ccc;
    border-radius: 6px;
    font-size: 1.4em;
    font-weight: bold;
`

const MethodPost = ({ data: { methods } }) => {
    const { t } = useTranslation()
    return (
        <Layout>
            <Helmet>
                {methods.featuredImage && (
                    <meta
                        property="og:image"
                        content={methods.featuredImage.url}
                    />
                )}
                <meta property="og:title" content={methods.title} />
            </Helmet>
            <Wrapper>
                <Seo title={methods.title} />
                <Spacer top="3" />
                <FaqBoxTitle>{t('methods')}</FaqBoxTitle>
                <Spacer top="4" />
                <Columns>
                    <MainTitle>{methods.title}</MainTitle>
                    <Spacer top="4" />
                    <Image obj={methods.featuredImage} />
                    <RichContent body={methods?.body?.raw} />
                </Columns>
                <Spacer top="12" />
                <FlexWrapper justifyContent="center" alignItems="center">
                    <Button link="/methods" arrow="blue">
                        {' '}
                        {t('back-to-methods')}{' '}
                    </Button>
                </FlexWrapper>
                <BlogSlider />
            </Wrapper>
        </Layout>
    )
}

export const query = graphql`
    query methods($slug: String) {
        methods: contentfulMethods(slug: { eq: $slug }) {
            id
            slug
            title
            updatedAt
            featuredImage {
                url
                title
                gatsbyImageData(
                    quality: 75
                    jpegProgressive: true
                    sizes: "1920, 960, 480"
                    cropFocus: CENTER
                )
            }
            body {
                raw
            }
        }
    }
`

export default MethodPost
